<script setup>
import { useListsStore } from '~/stores/account/lists.store';
import { useCustomerStore } from '~/stores/account/customers.store';

const route = useRoute();
const id = ref(route.params?.id);
const listsStore = useListsStore();
const customerStore = useCustomerStore();

const form = ref({
  full_name: null,
  email: null,
  mobile: null,
  user_id: null,
  category_id: null,
  source_id: null,
  country_id: null,
  city_id: null,
  instagram: null,
  notes: null,
});

onMounted(async () => {
  document.addEventListener('click', closeDropdown);
  await listsStore.fetchCustomerCategories();
  await listsStore.fetchCustomerSources();
  await listsStore.fetchUsers();
  await listsStore.fetchCountries({ has_customers: true });

  if (id.value) {
    form.value = await customerStore.fetchCustomer(id.value);
  }
});


onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdown);
});

const isDropdownOpen = ref(false);
const selectedCountry = ref();
const selectedFlagIcon = ref(`fa-solid fa-flag`);
const customerCategories = computed(() => listsStore.customerCategoriesGetter());
const customerSources = computed(() => listsStore.customerSourcesGetter());
const users = computed(() => listsStore.usersGetter());
const countries = computed(() => listsStore.countriesGetter());
const cities = computed(() => listsStore.citiesGetter());

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectCountry = async (country) => {
  selectedCountry.value = country.phone_code;
  selectedFlagIcon.value = `fi fi-${country.code}`;
  form.value.country_id = country.id;
  isDropdownOpen.value = false;
  await listsStore.fetchCitiesInCountry(country.id);
};

const closeDropdown = (event) => {
  const dropdownElement = document.querySelector('.custom-dropdown');
  if (dropdownElement && !dropdownElement.contains(event.target)) {
    isDropdownOpen.value = false;
  }
};

const updatePerson = () => {
  if (id.value) {
    customerStore.updateCustomer(form.value, id.value);
  }
  else {
    customerStore.addCustomer(form.value);
  }
}

</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="id ? $t('client.edit_customer') : $t('client.add_new_customer')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <ClientOnly>
      <div class="px-4">
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.name') }}</h3>
          <WInput type="text" name="full_name" :placeholder="$t('client.name')" v-model:value="form.full_name" />
        </div>
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.email') }}</h3>
          <WInput type="email" name="email" :placeholder="$t('client.email')" v-model:value="form.email" />
        </div>
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.instagram') }}</h3>
          <WInput type="text" name="instagram" :placeholder="$t('client.instagram')" v-model:value="form.instagram" />
        </div>
        <template v-if="!id">
          <div class="mt-3">
            <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.mobile_number') }}</h3>
            <div class="flex">
              <div class="custom-dropdown" @click="toggleDropdown">
                <div class="dropdown-selected">
                  <span :class="selectedFlagIcon"></span>{{ selectedCountry }}
                </div>
                <div class="dropdown-list shadow-lg" v-if="isDropdownOpen">
                  <div class="dropdown-item" v-for="country in countries" :key="country.id"
                    @click="selectCountry(country)">
                    <span :class="`fi fi-${country.code}`"></span>{{ country.name }} ({{ country.phone_code }})
                  </div>
                </div>
              </div>
              <WInput type="text" name="mobile" :placeholder="$t('client.mobile_number')"
                v-model:value="form.mobile" />
            </div>
          </div>
          <div class="mt-3">
            <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.selected_city') }}</h3>
            <WInput :select="true" name="city_id" :options="cities" v-model:value="form.city_id" />
          </div>
        </template>
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.agent_user') }}</h3>
          <WInput :select="true" name="user_id" :options="users" v-model:value="form.user_id" />
        </div>
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.category') }}</h3>
          <WInput :select="true" name="category_id" :options="customerCategories" v-model:value="form.category_id" />
        </div>
        <div class="mt-3">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.source') }}</h3>
          <WInput :select="true" name="source_id" :options="customerSources" v-model:value="form.source_id" />
        </div>
        <div class="mt-3" v-if="!id">
          <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.notes') }}</h3>
          <WEditor name="notes" v-model:value="form.notes" type="textarea" placeholder="Notes" />
        </div>
      </div>
      <template #fallback>
        <div class="relative h-screen">
          <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <span class="loader"></span>
          </div>
        </div>
      </template>
    </ClientOnly>

    <template #mobile-footer>
      <div class="fixed bottom-0 bg-white p-4 w-full">
        <div class="flex items-center gap-3">
          <AccountHasRole :role_key="id ? 'customers:update' : 'customers:store'">
            <button @click="updatePerson"
              class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">
              <i class="fa-solid me-3" :class="[id ? 'fa-pen' : 'fa-plus']"></i>
              {{ id ? $t('client.update_customer') : $t('client.add_new_customer') }}
            </button>
          </AccountHasRole>
        </div>
      </div>
    </template>
  </NuxtLayout>
</template>

<style scoped>
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-selected {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 7px;
  margin-right: 5px;
}

.dropdown-selected .fi {
  margin-right: 8px;
}

.dropdown-selected .fa-solid {
  padding: 5px;
}

.dropdown-list {
  position: fixed;
  top: 20px;
  bottom: 10px;
  left: 50%;
  right: 0;
  border: 1px solid #ccc;
  z-index: 999;
  width: 250px;
  height: 85vh;
  overflow-y: auto;
  margin-top: 5px;
  border-radius: 7px;
  transform: translateX(-50%);
  background-color: rgb(255 255 255 / 30%);
  backdrop-filter: blur(30px);
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
}

.dropdown-item .fi {
  margin-right: 8px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>